import Layout from "../../../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../../../components/seo"
import React, { useEffect, useState } from "react"
import dayjs from "dayjs"

import PressList from "../../../components/Media Components/presslist"
import DateFilter from "../../../components/Media Components/DateFilter"
import "../../../css/resources.css"
import "../../../css/pageBanner.css"
import Pagination from "../../../components/Media Components/Pagination"

const isSameOrAfter = require("dayjs/plugin/isSameOrAfter")
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore")
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
const isBetween = require("dayjs/plugin/isBetween")
dayjs.extend(isBetween)

function Press() {
  const drupalData = useStaticQuery(graphql`
    query PressreleaseQuery {
      allNodePressReleasePage {
        nodes {
          field_meta_title
          field_meta_desc
          field_press_release_heading
          field_press_release_link_text
          field_press_release_date
          field_information
          field_content_intro {
            processed
          }
          field_content_end {
            processed
          }
          field_press_release_schema_scrip {
            processed
          }
          relationships {
            field_press_release_banner_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            field_press_pdf {
              localFile {
                url
              }
            }
          }
        }
      }
    }
  `)
  const data = drupalData.allNodePressReleasePage.nodes

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [showPerPage, setShowPerPage] = useState(4)
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  })
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end })
  }

  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    let temp = []

    if (!startDate && !endDate) {
      data.forEach(item => {
        temp.push(item)
      })
      setFilterData(data)
    } else if (startDate && !endDate) {
      data.forEach(item => {
        if (dayjs(item.field_press_release_date).isSameOrAfter(startDate)) {
          temp.push(item)
        }
      })
    } else if (endDate && !startDate) {
      data.forEach(item => {
        if (dayjs(item.field_press_release_date).isSameOrBefore(endDate)) {
          temp.push(item)
        }
      })
    } else {
      data.forEach(item => {
        if (
          dayjs(item.field_press_release_date).isBetween(
            startDate,
            endDate,
            "[]"
          )
        ) {
          temp.push(item)
        }
      })
    }
    setFilterData(temp)
  }, [startDate, endDate])

  const getStartDate = value => {
    setStartDate(value)
  }
  const getEndDate = value => {
    setEndDate(value)
  }
  return (
    <Layout>
      <SEO
        title="Press-Releases | Tata Studi"
        lang="en"
        description="Find resources to help your child in developing a good study habit and become an independent and confident learner | Tata Studi"
      />
      <h1 className="displayNone">Videos | Tata Studi</h1>
      <div className="pageBanner blogsPage" style={{ position: "initial" }} />
      <div className="blogs_banner">
        <span>Press Releases</span>
      </div>

      <DateFilter getStartDate={getStartDate} getEndDate={getEndDate} />
      <div className="div_blogs">
        {filterData &&
          filterData
            .slice(pagination.start, pagination.end)
            .map((item, key) => <PressList item={item} id={key} key={key} />)}
            {/* // .map(item => <PressList item={item} key={item.id} />)} */}
      </div>
      <div className="mt-5">
        <Pagination
          showPerPage={showPerPage}
          onPaginationChange={onPaginationChange}
          total={data.length}
        />
      </div>

      <div
        className="row no-gutters"
        style={{ height: "150px", marginTop: "100px" }}
      ></div>
    </Layout>
  )
}

export default Press
